import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { HttpClient } from '@angular/common/http';

/**
 * @description Serviço responsável pelo gerenciamento de dados do usuário
 * @class UserService
 * @extends {GlobalService}
 */
@Injectable({
  providedIn: 'root'
})
export class UserService extends GlobalService{

  constructor(private _http: HttpClient) {
    super(_http);
    this.serviceUrl = '';
  }

  /**
   * @description Atualiza os dados da conta do usuário
   * @param {number} id - ID do usuário
   * @param {any} data - Dados a serem atualizados
   * @returns {Observable<any>} Observable com a resposta da requisição
   */
  patchDadosMinhaConta(id: number, data: any){
    return this.patch(id, data, 'update-user');
  }

  /**
   * @description Atualiza as configurações de segurança do usuário
   * @param {number} id - ID do usuário
   * @param {any} data - Dados de segurança a serem atualizados
   * @returns {Observable<any>} Observable com a resposta da requisição
   */
  patchDadosSeguranca(id: number, data: any){
    return this.patch(id, data);
  }

  /**
   * @description Verifica o código de validação durante o pré-registro
   * @param {any} data - Dados contendo o código a ser verificado
   * @returns {Observable<any>} Observable com a resposta da verificação
   */
  checkCode(data: any){
    return this.post(data, 'pre-register/check_code');
  }

  /**
   * @description Reenvia o código de validação
   * @param {any} data - Dados necessários para reenvio do código
   * @returns {Observable<any>} Observable com a resposta do reenvio
   */
  resendCode(data: any){
    return this.post(data, 'resend-code');
  }

  /**
   * @description Atualiza informações da conta do usuário
   * @param {number} id - ID do usuário
   * @param {any} data - Dados da conta a serem atualizados
   * @returns {Observable<any>} Observable com a resposta da atualização
   */
  patchUpdateAccount(id: number, data: any){
    return this.patch(id, data, 'update-account');
  }
}
